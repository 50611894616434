<template lang="pug">
.col-start-1.col-end-3.my-4.overflow-y-auto.overflow-x-hidden(
 
)
  table.w-full.table-fixed
    thead.text-left
      tr.border-b.border-reallylightblue
        th.bg-white.top-0.sticky.z-10
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ $t("objects_tabs.services.name_service") }}
        th.bg-white.top-0.sticky.z-10.truncate.w-36
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ $t("objects_tabs.services.interval_mileage") }}
        th.bg-white.top-0.sticky.z-10.w-36
          span.text-headerText.text-sm.font-SourceSansPro.leading-10.font-semibold {{ $t("objects_tabs.services.interval_days") }}
        th.w-6.text-center.top-0.sticky.z-10.bg-white
          svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-all.ease-in-out.duration-200.transform(
            :class='isNewService ? "rotate-45" : "rotate-0"',
            width='16',
            height='16',
            fill='none',
            xmlns='http://www.w3.org/2000/svg',
            @click='createService'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.75 4.75a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 101.5 0v-2.5h2.5a.75.75 0 100-1.5h-2.5v-2.5z'
            )
      tr(v-if='isNewService')
        th(:colspan='4')
          .flex.items-end.my-3
            .flex.flex-col.unitDriverModal(class='w-2/3')
              .flex.flex-col.relative
                span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.services.name_service") }}
                el-input.mt-3(
                  type='text',
                  v-model='newService.name',
                  :class='{ "input-error": isServiceNameMissing }'
                )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isServiceNameMissing'
                  ) {{ $t("notifications.enterName") }}

              .flex.flex-col.relative
                p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-6 {{ $t("objects_tabs.services.description") }}
                el-input.mt-3(
                  type='textarea',
                  resize='none',
                  maxlength=768,
                  autosize,
                  :rows='3',
                  :class='{ "input-error": isServiceDescriptionMissing }',
                  v-model='newService.description'
                )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isServiceDescriptionMissing'
                  ) {{ $t("objects_tabs.services.warning.description") }}
              .flex.flex-col.relative
                p.mt-6.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.services.last_one_date") }}
                el-date-picker.mt-3(
                  class='w-2/3',
                  :clearable='false',
                  type='date',
                  :placeholder='$t("objects_tabs.services.date.placeholder")',
                  v-model='newService.lastDays',
                  :picker-options='pickerOptions',
                  popper-class='datePicker',
                  :format='dateFormat',
                  :class='{ "input-error": isServiceLastDaysMissing }',
                  value-format='yyyy-MM-dd'
                )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isServiceLastDaysMissing'
                  ) {{ $t("objects_tabs.services.warning.date") }}
              skif-checkbox.mt-6(
                :label='$t("objects_tabs.services.active_on_interval_mileage")',
                v-model='newService.isActiveKm',
                :error='isServiceAnyCheckbox'
              )
              .flex.mb-2(v-show='newService.isActiveKm', style='width: 319px')
                .flex.flex-col.relative
                  p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.services.interval_mileage_numb_km") }}
                  skif-input-number.mt-3(
                    v-model.number='newService.intervalKm',
                    width='28',
                    :error='isServiceIntervalKmMissing'
                  )
                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isServiceIntervalKmMissing'
                    ) Введите промежуток пробега
                .flex.flex-col.ml-6.relative
                  p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.services.mileageInterval") }}
                  skif-input-number.mt-3(
                    v-model.number='newService.lastKm',
                    width='28',
                    :error='isServiceLastKmMissing'
                  )
                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isServiceLastKmMissing'
                    ) {{ $t("objects_tabs.services.mileageInterval") }}
              skif-checkbox.mt-8(
                :label='$t("objects_tabs.services.active_on_interval_days")',
                v-model='newService.isActiveDays',
                :error='isServiceAnyCheckbox'
              )
              .flex.flex-col.mt-3.relative(v-if='newService.isActiveDays')
                p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.services.interval_days_numb_day") }}
                skif-input-number.mt-3(
                  v-model.number='newService.intervalDays',
                  width='36',
                  :error='isServiceIntervalDaysMissing'
                )
                transition(name='el-zoom-in-top')
                  .text-notify.text-xs.top-full.left-0.absolute(
                    v-if='isServiceIntervalDaysMissing'
                  ) {{ $t("objects_tabs.services.intervalInDays") }}
              skif-checkbox.mt-8(
                :label='$t("objects_tabs.services.intervalInMotoHours")',
                v-model='newService.isActiveMotohours',
                :error='isServiceAnyCheckbox'
              )
              .flex.mt-3(v-show='newService.isActiveMotohours')
                .flex.flex-col.relative
                  p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.services.intervalInMotoHoursDate") }}
                  skif-input-number.mt-3(
                    v-model.number='newService.intervalMotohours',
                    width='28',
                    :error='isServiceIntervalMotohoursMissing'
                  )
                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isServiceIntervalMotohoursMissing'
                    ) Введите промежуток моточасов
                .flex.flex-col.ml-6.relative
                  p.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("objects_tabs.services.lastTimeDate") }}
                  skif-input-number.mt-8(
                    v-model.number='newService.lastMotohours',
                    width='28',
                    :error='isServiceLastMotohoursMissing'
                  )
                  transition(name='el-zoom-in-top')
                    .text-notify.text-xs.top-full.left-0.absolute(
                      v-if='isServiceLastMotohoursMissing'
                    ) Введите текущее показание моточасов

            .flex.justify-end(class='w-1/3')
              skif-button(width='28', @click='createOrUpdateService') {{ $t("btn.save") }}

    tbody(v-if='!isNewService')
      tr.cursor-pointer.hover_bg-hoverrow(
        v-for='service in filterDataServices',
        :key='service.id',
        @click='editService(service)'
      )
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-sm {{ service.name }}
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-sm {{ service.intervalKm }}
        td.truncate.pr-2.text-darkblue
          span.font-bold.leading-10.text-sm {{ service.intervalDays }}
        td.flex.items-center.justify-center
          svg.cursor-pointer.fill-annotationColor.hover_fill-fillTrash.transition-colors.ease-in-out.duration-200(
            width='12',
            height='16',
            fill='none',
            xmlns='http://www.w3.org/2000/svg',
            @click='(event) => { event.stopPropagation(); deleteService(service.id); }'
          )
            path(
              fill-rule='evenodd',
              clip-rule='evenodd',
              d='M3 1H0v2h12V1H9L8 0H4L3 1zm8 3H1v10a2 2 0 002 2h6a2 2 0 002-2V4z'
            )
</template>

<script>
import service from '../mixins/service'
export default {
  mixins: [service],
  props: ['unit', 'pickerOptions', 'dateFormat']
}
</script>

<style></style>
